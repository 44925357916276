import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby-theme-material-ui"
import {
  Typography,
  Grid,
  Hidden,
  Divider,
  IconButton,
} from "@material-ui/core"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import clsx from "clsx"
import FacebookIcon from "@material-ui/icons/Facebook"
// import TwitterIcon from "@material-ui/icons/Twitter"
// import InstagramIcon from '@material-ui/icons/Instagram'
import CityL from "../../assets/Footer/city-background-left.svg"
import CityR from "../../assets/Footer/city-background-right.svg"
import Logo from "../../assets/Logo/logo-cfl-white.svg"
import Buro from "../../assets/Logo/logo_buro.png"
import Condusef from "../../assets/Logo/logo_condusef_claro.png"
import CNVB from "../../assets/Logo/cnvb.svg"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import ReinvestmentLetter from "../../../static/PDF/Reinvestment_letter.pdf"
import TerminationAgreement from "../../../static/PDF/Termination_agreement.pdf"
import SpecializedUserServiceUnit from "../../../static/PDF/Specialized_User_Service_Unit.pdf"

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none",
    padding: "25px",
    background: "#333D46",
  },
  logo: {
    width: "150px",
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  imgFooterLeft: {
    width: "100%",
    height: "100%",
    margin: "0",
    marginBottom: "-2.7em",
  },
  imgFooterRight: {
    width: "100%",
    height: "100%",
    margin: "0",
    marginBottom: "-2.1em",
  },
  upLink: {
    color: "#888888",
    textDecoration: "none",
    padding: theme.spacing(1, 0, 0),
    display: "inline-block",
    "&:hover": {
      color: theme.palette.common.white,
    },
  },

  icon: {
    color: "#888888",
    fontSize: 32,
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
  textcolor: {
    color: "#888888",
  },
}))

const Footer = ({ className }) => {
  const classes = useStyles()
  return (
    <div>
      <Grid container justify="center" alignItems="flex-end">
        <Hidden mdDown>
          <Grid item xs={6}>
            <img
              alt="Footer imagen"
              src={CityL}
              className={classes.imgFooterLeft}
            />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={6}>
          <img
            alt="Footer imagen"
            src={CityR}
            className={classes.imgFooterRight}
          />
        </Grid>
      </Grid>
      <div className={clsx(classes.root, className)}>
        <Container>
          <Box py={4}>
            <Grid
              container
              spacing={3}
              justify="center"
              alignItems="flex-start"
            >
              <Grid
                item
                xs={12}
                sm={2}
                container
                direction="column"
                justifyContent="space-around"
              >
                <Link to="/">
                  <img
                    alt="Logo"
                    src={Logo}
                    className={classes.logo}
                    width="100px"
                  />
                </Link>
                <Box py={2}>
                  <Grid container alignItems="center">
                    <Link
                      target="_black"
                      to="https://cfl-documents-info.s3.us-east-2.amazonaws.com/Bur%C3%B3+de+Entidades+Financieras+EMG.pdf"
                    >
                      <img
                        alt="Footer imagen"
                        src={Buro}
                        width="50px"
                        style={{
                          marginTop: "5px",
                        }}
                      />
                    </Link>
                    <Link
                      to="https://www.condusef.gob.mx/index.php"
                      target="_black"
                      style={{
                        height: "55px",
                      }}
                    >
                      <img
                        alt="Footer imagen"
                        src={Condusef}
                        width="100%"
                        height="100%"
                      />
                    </Link>
                    <Link
                      to="https://www.gob.mx/cnbv"
                      target="_black"
                      style={{
                        height: "43px",
                      }}
                    >
                      <img
                        alt="Footer imagen"
                        src={CNVB}
                        width="100%"
                        height="100%"
                        style={{
                          paddingLeft: "3px",
                          objectFit: "contain",
                        }}
                      />
                    </Link>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography
                  gutterBottom
                  variant="body2"
                  component="h4"
                  className={classes.textcolor}
                >
                  INVERSIONISTAS
                </Typography>
                <Typography className={classes.textcolor} variant="body2">
                  <Link
                    align="center"
                    to="/#HIW"
                    variant="body2"
                    className={classes.upLink}
                  >
                    ¿Cómo funciona?
                  </Link>
                </Typography>
                <Typography className={classes.textcolor} variant="body2">
                  <Link
                    align="center"
                    to={`${process.env.GATSBY_APP_LENDER}/register/email?profileType=personal&lang=es-mx`}
                    variant="body2"
                    className={classes.upLink}
                  >
                    Productos
                  </Link>
                </Typography>
                <Typography className={classes.textcolor} variant="body2">
                  <Link
                    align="center"
                    color="secondary"
                    to={"/#Inversion"}
                    variant="body2"
                    className={classes.upLink}
                  >
                    Simulador
                  </Link>
                </Typography>
                <Typography className={classes.textcolor} variant="body2">
                  <Link
                    align="center"
                    color="secondary"
                    to={"/Risks"}
                    variant="body2"
                    className={classes.upLink}
                  >
                    Riesgos
                  </Link>
                </Typography>
                <Typography className={classes.textcolor} variant="body2">
                  <Link
                    align="center"
                    color="secondary"
                    to="/Costes-Comisiones/"
                    variant="body2"
                    className={classes.upLink}
                  >
                    Tasas
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography
                  gutterBottom
                  variant="body2"
                  component="h4"
                  className={classes.textcolor}
                >
                  SOLICITANTES
                </Typography>
                <Typography className={classes.textcolor} variant="body2">
                  <Link
                    align="center"
                    to="/#HIW"
                    variant="body2"
                    className={classes.upLink}
                  >
                    ¿Cómo funciona?
                  </Link>
                </Typography>
                <Typography className={classes.textcolor} variant="body2">
                  <Link
                    align="center"
                    to={`${process.env.GATSBY_APP_BORROWER}/authentication/register`}
                    variant="body2"
                    className={classes.upLink}
                  >
                    Productos
                  </Link>
                </Typography>
                <Typography className={classes.textcolor} variant="body2">
                  <Link
                    align="center"
                    color="secondary"
                    to={"/#Credito"}
                    variant="body2"
                    className={classes.upLink}
                  >
                    Simulador
                  </Link>
                </Typography>
                <Typography className={classes.textcolor} variant="body2">
                  <Link
                    align="center"
                    to={"/#Credito"}
                    variant="body2"
                    className={classes.upLink}
                  >
                    Beneficios de CFL
                  </Link>
                </Typography>
                <Typography className={classes.textcolor} variant="body2">
                  <Link
                    align="center"
                    color="secondary"
                    to={"/Solicitantes-Tasas/"}
                    variant="body2"
                    className={classes.upLink}
                  >
                    Tasas
                  </Link>
                </Typography>
              </Grid>
              {/*
              <Grid item xs={12} sm={1}>
                <Typography
                  gutterBottom
                  variant="body2"
                  component="h4"
                  className={classes.textcolor}
                >
                  INFORMACIÓN
                </Typography>
                <Typography className={classes.textcolor} variant="body2">
                  <Link
                    align="center"
                    color="secondary"
                    to="/"
                    variant="body2"
                    className={classes.upLink}
                  >
                    Capital Funding Lab
                  </Link>
                </Typography>
                <Typography color="secondary" variant="body2">
                  <Link
                    align="center"
                    color="secondary"
                    to="/Contacto"
                    variant="body2"
                    className={classes.upLink}
                  >
                    Contacto
                  </Link>
                </Typography>
                <Typography color="secondary" variant="body2">
                  <Link
                    align="center"
                    color="secondary"
                    to="/Analisis"
                    variant="body2"
                    className={classes.upLink}
                  >
                    Análisis
                  </Link>
                </Typography>
                <Typography color="secondary" variant="body2">
                  <Link
                    align="center"
                    color="secondary"
                    to="/CasosdeExito"
                    variant="body2"
                    className={classes.upLink}
                  >
                    Casos de éxito
                  </Link>
                </Typography>
              </Grid>*/}
              <Grid item xs={12} sm={2}>
                {" "}
                <Typography
                  gutterBottom
                  variant="body2"
                  component="h4"
                  className={classes.textcolor}
                >
                  LEGAL
                </Typography>
                <Typography variant="body2">
                  <Link
                    align="center"
                    to="https://www.capitalfundinglab.com/Terminos-y-Condiciones"
                    variant="body2"
                    className={classes.upLink}
                  >
                    T&C
                  </Link>
                </Typography>
                <Typography className={classes.textcolor} variant="body2">
                  <Link
                    align="center"
                    color="secondary"
                    to="https://www.capitalfundinglab.com/Aviso-De-Privacidad"
                    variant="body2"
                    className={classes.upLink}
                  >
                    Aviso de Privacidad
                  </Link>
                </Typography>
                <Typography className={classes.textcolor} variant="body2">
                  <Link
                    align="center"
                    color="secondary"
                    to="/Politicas-de-cookies"
                    variant="body2"
                    className={classes.upLink}
                  >
                    Políticas de Cookies
                  </Link>
                </Typography>
                {/* <Typography className={classes.textcolor} variant="body2">
                  <a
                    href={ComisionMercantil}
                    target="_blank"
                    className={classes.upLink}
                  >
                    Contrato de Comisión Mercantil
                  </a>
                </Typography>
                <Typography className={classes.textcolor} variant="body2">
                  <a
                    href={CartaReinversion}
                    target="_blank"
                    className={classes.upLink}
                  >
                    Carta de Reinversión
                  </a>
                </Typography> */}
                <Typography className={classes.textcolor} variant="body2">
                  <Link
                    align="center"
                    color="secondary"
                    to="/Quejas"
                    variant="body2"
                    className={classes.upLink}
                  >
                    Reclamaciones
                  </Link>
                </Typography>
                <Typography className={classes.textcolor} variant="body2">
                  <a
                    href={ReinvestmentLetter}
                    target="_blank"
                    className={classes.upLink}
                  >
                    Carta de reinversión automática
                  </a>
                </Typography>
                <Typography className={classes.textcolor} variant="body2">
                  <a
                    href={TerminationAgreement}
                    target="_blank"
                    className={classes.upLink}
                  >
                    Convenio de terminación de Operaciones
                  </a>
                </Typography>
                <Typography className={classes.textcolor} variant="body2">
                  <a
                    href={SpecializedUserServiceUnit}
                    target="_blank"
                    className={classes.upLink}
                  >
                    UNE (Unidad Especializada de Atención al Usuario)
                  </a>
                </Typography>
                <Typography className={classes.textcolor} variant="body2">
                  <a
                    href="mailto:cumplimientocfl@capitalfundinglab.com"
                    className={classes.upLink}
                  >
                    Denuncia anónima de Operaciones con Recursos de Procedencia
                    Ilícita.
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                {" "}
                <Typography
                  gutterBottom
                  variant="body2"
                  component="h4"
                  className={classes.textcolor}
                >
                  ACERCA DE CFL
                </Typography>
                <Typography variant="body2">
                  <Link
                    align="center"
                    to="/CflDifferent"
                    variant="body2"
                    className={classes.upLink}
                  >
                    ¿Por qué CFL es diferente?
                  </Link>
                </Typography>
                <Typography className={classes.textcolor} variant="body2">
                  <Link
                    align="center"
                    color="secondary"
                    to="/CFLValues"
                    variant="body2"
                    className={classes.upLink}
                  >
                    Valores
                  </Link>
                </Typography>
                <Typography className={classes.textcolor} variant="body2">
                  <Link
                    align="center"
                    color="secondary"
                    // to="/Mantenimiento"
                    to="https://cfl-documents-info.s3.us-east-2.amazonaws.com/cumplimiento/anexo17.pdf"
                    variant="body2"
                    className={classes.upLink}
                    target="_blank"
                  >
                    CFL en cifras
                  </Link>
                </Typography>
                <Typography className={classes.textcolor} variant="body2">
                  <Link
                    align="center"
                    color="secondary"
                    to="https://cfl-documents-info.s3.us-east-2.amazonaws.com/cumplimiento/AuditoriaContable.pdf"
                    variant="body2"
                    className={classes.upLink}
                    target="_blank"
                  >
                    Auditoría contable
                  </Link>
                </Typography>
                <Typography className={classes.textcolor} variant="body2">
                  <Link
                    align="center"
                    color="secondary"
                    to="/"
                    variant="body2"
                    className={classes.upLink}
                  >
                    Carreras
                  </Link>
                </Typography>
                <Typography className={classes.textcolor} variant="body2">
                  <Link
                    align="center"
                    color="secondary"
                    to="/Preguntas-frecuentes"
                    variant="body2"
                    className={classes.upLink}
                  >
                    FAQ
                  </Link>
                </Typography>
                <Typography className={classes.textcolor} variant="body2">
                  <Link
                    color="secondary"
                    to="/Costes-Comisiones/"
                    variant="body2"
                    className={classes.upLink}
                  >
                    Consulte los costes y comisiones de nuestros productos
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography
                  gutterBottom
                  variant="body2"
                  component="h4"
                  className={classes.textcolor}
                >
                  REDES SOCIALES
                </Typography>

                <IconButton href="https://www.facebook.com/capitalfundinglab">
                  <FacebookIcon className={classes.icon} />
                </IconButton>
                <IconButton href="https://api.whatsapp.com/send/?phone=5215510598451&text=Hola%2C+me+gustar%C3%ADa+me+asesoraras+en+&app_absent=0">
                  <WhatsAppIcon className={classes.icon} />
                </IconButton>
                {/* <IconButton href="https://twitter.com/Capital_FLab">
                  <TwitterIcon className={classes.icon} />
                </IconButton>
                <IconButton href="https://www.instagram.com/capitalfundinglab/">
                  <InstagramIcon className={classes.icon} />
                </IconButton> */}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Divider className={classes.divider} />
              <Typography
                variant="body2"
                gutterBottom
                className={classes.textcolor}
              >
                Powered by Capital Funding Lab © 2021 Todos los derechos
                reservados
              </Typography>
              <Typography
                variant="body2"
                className={classes.textcolor}
                gutterBottom
                align="justify"
              >
                Emprendimientos Maussan y González, S.A.P.I., de C.V.,
                Institución de Financiamiento Colectivo ("CFL") es una sociedad
                autorizada para organizarse y operar como Institución de
                Financiamiento Colectivo por la Comisión Nacional Bancaria y de
                Valores, por lo que se encuentra autorizada, regulada y
                supervisada por las Autoridades Financieras. Lo anterior,
                mediante oficio número P176/2021 publicado en el Diario Oficial
                de la Federación el seis de abril de dos mil veintitrés.
              </Typography>
              <Typography
                variant="body2"
                className={classes.textcolor}
                gutterBottom
                align="justify"
              >
                El Gobierno Federal y las Entidades de la Administración Pública
                Paraestatal no podrán responsabilizarse o garantizar los
                recursos de los Usuarios que sean utilizados en las operaciones
                que celebren con las Instituciones de Tecnología Financiera o
                frente a otros, ni asumir alguna responsabilidad por las
                obligaciones contraídas por las Instituciones de Tecnología
                Financiera o por algún Usuario frente a otro, en virtud de las
                operaciones que celebren
              </Typography>
              <Typography
                variant="body1"
                align="right"
                className={classes.textcolor}
              >
                {`${process.env.GATSBY_APP_VERSION || "v0.0.0"}`}
              </Typography>
            </Grid>
          </Box>
        </Container>
      </div>
    </div>
  )
}

export default Footer
