import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import {
  Hidden,
  Drawer,
  Button,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Typography,
  IconButton,
  withWidth,
  Grid
} from "@material-ui/core"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import MenuIcon from "@material-ui/icons/Menu"
import CloseIcon from "@material-ui/icons/Close"

import ListItem from "@material-ui/core/ListItem"

import ListItemText from "@material-ui/core/ListItemText"
import Logo from "../../assets/Logo/logo-cfl-dark.svg"

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    background: "transparent"
  },
  appBar: {
    background: "transparent !important",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `100%`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.common.black
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  logIn: {
    color: theme.palette.common.black
  },
  register: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    borderRadius: "20px"
  },
  items: { marginTop: "0.5em" },
  imgHeader: {
    width: "6em",
    [theme.breakpoints.up("md")]: {
      width: "10em"
    }
  },
  links: {
    textDecoration: "none",
    color: theme.palette.common.black
  }
}))

const Header = ({ siteTitle, props }) => {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)

  function handleDrawerOpen() {
    setOpen(true)
  }

  function handleDrawerClose() {
    setOpen(false)
  }
  function Links() {
    return (
      <Grid container justify="space-evenly">
        <Grid item>
          <Link to="/#HIW" className={classes.links}>
            <Typography variant="h6" component="span" align="center">
              Cómo funciona
            </Typography>
          </Link>
        </Grid>
        <Grid item>
          <Link to="/#Inversion" className={classes.links}>
            <Typography variant="h6" component="span" align="center">
              Inversiones
            </Typography>
          </Link>
        </Grid>
        <Grid item>
          <Link to="/#Credito" className={classes.links}>
            <Typography variant="h6" component="span" align="center">
              Créditos
            </Typography>
          </Link>
        </Grid>
        <Grid item>
          <Link to="/CasosdeExito" className={classes.links}>
            <Typography variant="h6" component="span" align="center">
              Casos de éxito
            </Typography>
          </Link>
        </Grid>
        <Grid item>
          <Link to="/CFLValues" className={classes.links}>
            <Typography variant="h6" component="span" align="center">
              Valores de CFL
            </Typography>
          </Link>
        </Grid>
        <Grid item>
          <Link to="/Contacto" className={classes.links}>
            <Typography variant="h6" component="span" align="center">
              Ayuda
            </Typography>
          </Link>
        </Grid>
      </Grid>
    )
  }
  function Buttons() {
    return (
      <>
        <Button
          style={{ marginRight: "16px" }}
          variant="text"
          color="default"
          href={process.env.GATSBY_APP_LENDER}
        >
          Entrar
        </Button>

        <Button
          variant="contained"
          className={classes.register}
          href={`${process.env.GATSBY_APP_LENDER}/register/email?profileType=personal&lang=es-mx`}
        >
          Regístrate
        </Button>
      </>
    )
  }
  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar
        position="absolute"
        elevation={0}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <Container>
            <Box py={2}>
              <Grid container justify="space-between" alignItems="center">
                <Grid
                  container
                  item
                  xs={5}
                  lg={2}
                  justify="flex-start"
                  className={classes.items}
                >
                  <Link to="/" className={classes.links}>
                    <img
                      alt="Logo capital funding Lab"
                      src={Logo}
                      className={classes.imgHeader}
                    />
                  </Link>
                </Grid>
                <Hidden lgUp>
                  <Grid container justify="center" item xs={5} lg={2}>
                    <Button variant="contained" className={classes.register} href={`${process.env.GATSBY_APP_LENDER}/register/email?profileType=personal&lang=es-mx`}>
                      Regístrate
                    </Button>
                  </Grid>
                </Hidden>
                <Hidden mdDown>
                  <Grid container item xs={12} lg={7} justify="space-around">
                    <Links />
                  </Grid>
                  <Grid container justify="flex-end" item xs={12} lg={3}>
                    <Buttons />
                  </Grid>
                </Hidden>
              </Grid>
            </Box>
          </Container>
          <Hidden lgUp>
            <IconButton
              color="inherit"
              aria-label="Open"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Hidden lgUp implementation="css">
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={open}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <List>
            <Link to="/" className={classes.links}>
              <ListItem button>
                <ListItemText align="center">Home</ListItemText>
              </ListItem>
            </Link>
            <Link to="/#HIW" className={classes.links}>
              <ListItem button>
                <ListItemText align="center">Cómo funciona</ListItemText>
              </ListItem>
            </Link>
            <Link to="/#Inversion" className={classes.links}>
              <ListItem button>
                <ListItemText align="center">Inversiones</ListItemText>
              </ListItem>
            </Link>
            <Link to="/#Credito" className={classes.links}>
              <ListItem button>
                <ListItemText align="center">Créditos</ListItemText>
              </ListItem>
            </Link>
            <Link to="/Contacto" className={classes.links}>
              <ListItem button>
                <ListItemText align="center">Ayuda</ListItemText>
              </ListItem>
            </Link>
          </List>
          <Box p={3} style={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="outlined"
              color="default"
              href={process.env.GATSBY_APP_LENDER}
              style={{ width: "100%" }}
            >
              Entrar
            </Button>
          </Box>
        </Drawer>
      </Hidden>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired
}

Header.defaultProps = {
  siteTitle: ``
}

export default withWidth()(Header)
