import { red } from "@material-ui/core/colors"
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles"
// A custom theme for this app

let theme = createMuiTheme({
  palette: {
    default: {
      main: "#222",
      contrastText: "#fff",
      dark: "#222",
      light: "#222"
    },
    primary: {
      light: "#757ce8",
      main: "#291C64",
      dark: "#002884",
      contrastText: "#fff"
    },
    secondary: {
      light: "#0CA8B0",
      main: "#0CA8B0",
      dark: "#0CA8B0",
      contrastText: "#fff"
    },
    error: {
      main: red.A400
    },
    background: {
      default: "#fff"
    },
    backgroundFooter: {
      main: "#333D46"
    },
    textSecondary: {
      main: "#888888",
      default: "#fff"
    }
  },
  typography: {
    h1: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "45px",
      letterSpacing: "-0.24px"
    },
    h2: {
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "38px",
      letterSpacing: "-0.24px"
    },
    h3: {
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "30px",
      letterSpacing: "-0.06px"
    },
    h4: {
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "20px",
      letterSpacing: "-0.06px"
    },
    h5: {
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "16px",
      letterSpacing: "-0.05px"
    },
    h6: {
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "14px",
      letterSpacing: "-0.05px"
    },
    subtitle1: {
      fontFamily: "Poppins",
      fontSize: "20px",
      letterSpacing: "-0.05px"
    },
    subtitle2: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "18px",
      letterSpacing: "-0.05px"
    },
    body1: {
      fontFamily: "Poppins",
      fontSize: "16px",
      letterSpacing: "-0.05px"
    },
    body2: {
      fontFamily: "Poppins",
      fontSize: "14px",
      letterSpacing: "-0.04px"
    },
    button: {
      fontFamily: "Poppins",
      fontSize: "16px"
    },
    caption: {
      fontFamily: "Poppins",
      fontSize: "12px",
      letterSpacing: "0.33px"
    },
    overline: {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
      // textTransform: "uppercase",
      color: "#979797"
    }
  },
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: 8
      }
    },
    MuiButton: {
      root: {
        borderRadius: 50
      },
      contained: {
        color: "#fff",
        backgroundColor: "#222"
      }
    }
  }
})

theme = responsiveFontSizes(theme)

export default theme
